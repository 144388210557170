import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  StatusAlert,
  ChargerPowerChart,
  Middlebar,
  ChargerPie,
  EventTimeline,
} from "components";

import { fetchFromTimestream } from 'api'

import { MdPower } from 'react-icons/md'
import { LuPlugZap2 } from 'react-icons/lu'
import { AiOutlineCalendar, AiOutlineBarChart } from 'react-icons/ai'

import faulted_charger from 'images/faulted_charger.png'
import ready_charger from 'images/ready_charger.png'
import warning_charger from 'images/warning_charger.png'
import charging_charger from 'images/charging_charger.png'

const ChargerModal = ({ selectedCharger, chargerStats, chargerAlerts, start, end }) => {

  const [finalGenerationData, setFinalGenerationData] = useState([])
  const [finalUsageData, setFinalUsageData] = useState([])

  const { locationId } = useParams();

  const chargerName = selectedCharger?.attributes?.FriendlyName
  const finalHeader = [chargerName]
  // const finalSubheadings = [`Manufacturer`, `${selectedCharger?.attributes?.manufacturer}`]
  const finalSubheadings = []

  // const chargerAlerts = []
  const chargingEventTimes = []

  useState(() => {

    // fetchFromTimestream(`SELECT DATE_DIFF('minute', '${startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS')}', TimeBin) / 60.0, SUM(chargeCutoff) / 1000.0 as TotalSoC FROM (SELECT bin(time, 15m) as TimeBin, DeviceName, chargeCutoff, ROW_NUMBER() OVER (PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC) as rn FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE DeviceType = 'charger' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}') as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`)
    fetchFromTimestream(`SELECT date_diff('minute', '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}', time) / 60.0, chargeCutoff / 1000.0 FROM  "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND DeviceName = '${selectedCharger?.attributes?.FriendlyName}'`)  
    .then(data => {
        const finalUsageData = data?.rows?.map(bin => { return { 'x': bin?.data[0].scalarValue, 'y': bin?.data[1].scalarValue } })
        setFinalUsageData(finalUsageData)
      })
      .catch(err => {
        console.log(err)
      })

    fetchFromTimestream(`SELECT date_diff('minute', '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}', time) / 60.0, gridDrawOff / 1000.0 FROM  "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND DeviceName = '${selectedCharger?.attributes?.FriendlyName}'`)
      .then(data => {
        const finalGenerationData = data?.rows?.map(bin => { return { 'x': bin?.data[0].scalarValue, 'y': bin?.data[1].scalarValue } })
        setFinalGenerationData(finalGenerationData)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const upperBound = end.diff(start, 'hour') + 1

  // const finalNetData = netData.filter(point => parseFloat(point.x) > lowerHourBound && parseFloat(point.x) < upperHourBound);
  const finalNetData = []


  var statColor = "#999999";
  var imgSource = ready_charger
  if (selectedCharger?.state == 'faulted') {
    statColor = "#c55b57"
    imgSource = faulted_charger
  }
  else if (selectedCharger?.state == 'ready') {
    statColor = "#999999"
    imgSource = ready_charger
  }
  else if (selectedCharger?.state == 'warning') {
    statColor = "#f2a93c"
    imgSource = warning_charger
  }
  else if (selectedCharger?.state == 'charging') {
    statColor = "#6e9f66"
    imgSource = charging_charger
  }

  return (
    <div>
      <div className="flex">
        <Middlebar /*pageTree={time}*/ header={finalHeader} subheadings={finalSubheadings} />
        {/* {
          // Image selection for different chargers
          (charger?.type === 'Electro.Aero 240kWh') &&
          <img src={`/RAPIDCharger240kW.png`} className="w-100 h-min my-3" alt="" />
        } */}
      </div>

      <div className="mt-2 mr-3">

        <div className="flex mb-3">
          <div
            className='flex flex-col h-190 w-275 shadow-md rounded-lg overflow-auto mx-2 text-white'
          >
            <div className='flex text-center items-center py-2' style={{ background: statColor }}>
              <div className='ml-5 flex-1 text-start'>
                {/* {`${selectedCharger?.version}`} */}
              </div>
              <MdPower fontSize={18} />
              <div className='ml-1 font-bold mr-5'>
                {selectedCharger?.attributes?.FriendlyName}
              </div>
            </div>

            <div className='flex-1 flex'>
              <div className='w-2/3 relative'>
                {/* <div className='relative top-1/2 left-0 text-center items-center'> */}
                <div className='absolute flex justify-center items-center w-full h-full'>
                  {
                    <img className="w-14" src={imgSource} alt='aerovy logo' />
                  }
                </div>
                <ChargerPie chargerStat={chargerStats} size={65} />
              </div>

              <div className='flex flex-col justify-center'>
                <div className='flex my-1 text-chDisp text-lg'>
                  <LuPlugZap2 fontSize={25} />
                  {`${chargerStats?.dispensed}`}
                </div>
                <div className='flex my-1 text-chRem text-lg'>
                  <AiOutlineCalendar fontSize={25} />
                  {`${chargerStats?.scheduled}`}
                </div>
                <div className='flex my-1 text-gray-400 text-lg'>
                  <AiOutlineBarChart fontSize={25} />
                  {`${chargerStats?.possible}`}
                </div>
              </div>

            </div>
          </div>

          <div className="flex-1 ml-3 rounded-md shadow-md">
            <StatusAlert chargerAlerts={{ chargerName: chargerAlerts }} />
          </div>

        </div>

        <div className="mb-3">
          <ChargerPowerChart
            usageData={finalUsageData}
            generationData={finalGenerationData}
            upperBound={upperBound}
          />
        </div>

        {/* <div className="flex flex-col bg-white rounded-xl shadow-xl p-3">
          //Currently only using events that would come in from the charger schedule, will include other events ie failures, updates, etc in future
          <ChargerEvents chargerEvents={finalChargerEvents} />
        </div> */}

        <div className="flex flex-col bg-white shadow-xl rounded-sm">
          <EventTimeline chargingEventTimes={chargingEventTimes} start={start} end={end} />
        </div>

      </div>
    </div>
  )
}

export default ChargerModal;