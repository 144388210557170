import React, { useState, useContext, useEffect } from "react";
import { Routes, Route } from 'react-router-dom'

import { AccountContext } from 'cognito/Account'
import { Login } from 'cognito'

import { VertiportDashboard, Settings, Charger, LocationSelection } from 'pages'
import { Sidebar } from 'components'

const App = () => {

  const [auth, setAuth] = useState(false)
  // const [username, setUsername] = useState("")
  const { getSession } = useContext(AccountContext)

  useEffect(() => {
    getSession()
      .then((session) => {
        console.log("Session: ", session)
        console.log("Session profile Id:", session.idToken.payload.profile)
        // setUsername(session.idToken.payload.email)
        localStorage.setItem("access_token", session.accessToken.jwtToken);
        setAuth(true)
      }).catch((err) => {
        localStorage.removeItem("access_token");
        // NOTE CHANGED TO TRUE WHEN LOGIN DETAILS ARE BEING BYPASSED, SET TO FALSE WHEN LOGIN SHOULD BE IN PLACE
        setAuth(false)
      })

      // getEventCount('2023-10-28 23:00:00.000', '2023-10-31 00:00:00.000')

  }, [getSession])


  if (!auth) return (
    <Login />
  )

  return (
    <div className="flex bg-gray-100  ">
      <Sidebar />
      <div className="flex flex-1 flex-col min-h-screen h-fit min-w-screen mx-24">
        <Routes>
          <Route path='/' element={<LocationSelection />} />
          <Route path='/:locationId/' element={<VertiportDashboard />} />
          <Route path='/settings' element={<Settings />} />
        </Routes>
      </div>
    </div>
  )
}

export default App;