import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import { ChargerPie, Pin } from 'components'

import { getSites, getDevices, getEventCounts, fetchFromTimestream } from 'api'

import Map, { Marker/*m GeolocateControl, FullscreenControl, NavigationControl, ScaleControl, Popup */ } from 'react-map-gl';

import { BarChart, Bar, /*Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,*/ ResponsiveContainer } from 'recharts';

import { IoMdPin } from "react-icons/io";
import { LuMapPin, LuPlugZap2 } from "react-icons/lu";
import { HiArrowsExpand } from "react-icons/hi";
import { AiOutlineCalendar, AiOutlineBarChart } from 'react-icons/ai'
import { PiBatteryChargingVerticalFill } from "react-icons/pi";
import { FaPlugCircleBolt } from "react-icons/fa6";


const LocationSelection = () => {

  const navigate = useNavigate();
  const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1']

  const [siteEventCounts, setSiteEventCounts] = useState([])
  const [locations, setLocations] = useState([])
  const [locationCardStats, setLocationCardStats] = useState({})

  useEffect(() => {
    getSites()
      .then(data => {
        setLocations(data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const groupDataByTimestamp = (rows) => {
      const groupedData = {};

      rows.forEach((row, index) => {
        const timestamp = row.data[0].scalarValue;

        if (!groupedData[timestamp]) {
          groupedData[timestamp] = {};
        }

        // Assuming the device type is at the second position and count at the third
        const deviceType = row.data[1].scalarValue;
        const count = parseInt(row.data[2].scalarValue, 10);

        groupedData[timestamp][deviceType] = count;
      });

      return groupedData;
    };

    const transformToRechartsFormat = (groupedData) => {
      return Object.keys(groupedData).map(timestamp => ({
        name: timestamp,
        ...groupedData[timestamp]
      }));
    };

    const fetchAndStoreCounts = async (siteId) => {
      try {
        const eventCounts = await getEventCounts(siteId);
        const groupedData = groupDataByTimestamp(eventCounts?.rows);
        return transformToRechartsFormat(groupedData);
      } catch (err) {
        console.log(err);
        return [];
      }
    };

    const updateSiteEventCounts = async () => {
      if (locations.length > 0) {
        let updatedSiteEventCounts = {};

        for (let location of locations) {
          const siteId = location?.siteId;
          const counts = await fetchAndStoreCounts(siteId);

          updatedSiteEventCounts[siteId] = counts;
        }
        setSiteEventCounts(updatedSiteEventCounts);
      }
    };

    updateSiteEventCounts();

    let locationCardStats = {}
    const fetchAndStoreLocationCardStats = async (siteId, statDict) => {
      try {
        const gridAndDispensed = await fetchFromTimestream(`SELECT SUM(EnergyUsedWh) AS TotalEnergyUsedWh, SUM(GridDrawOffWh) AS TotalGridDrawOffWh FROM(SELECT chargeCutoff * date_diff('second', LAG(time) OVER(ORDER BY time), time) / 3600.0 AS EnergyUsedWh, gridDrawOff * date_diff('second', LAG(time) OVER(ORDER BY time), time) / 3600.0 AS GridDrawOffWh FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${siteId}_Data" WHERE DeviceType = 'charger') AS subquery`)
        const totalSoC = await fetchFromTimestream(`WITH LatestSoC AS(SELECT DeviceName, soc, ROW_NUMBER() OVER(PARTITION BY DeviceName ORDER BY time DESC) AS rn FROM  "00000000-ad9d-4025-81ab-e6083532bb7b"."${siteId}_Data" WHERE DeviceType = 'battery') SELECT SUM(soc) * 2000 AS TotalSoC FROM LatestSoC WHERE rn = 1`)
        const devices = await getDevices(siteId)

        const batteryCount = devices.filter(device => device?.thingTypeName.startsWith("battery")).length
        const chargerCount = devices.filter(device => device?.thingTypeName.startsWith("charger")).length

        locationCardStats[siteId] = {
          'batteryCount': batteryCount,
          'chargerCount': chargerCount,
          'dispensed': Math.floor(gridAndDispensed?.rows[0]?.data[0]?.scalarValue / 1000),
          'grid': Math.floor(gridAndDispensed?.rows[0]?.data[1]?.scalarValue / 1000),
          'stored': Math.floor(totalSoC?.rows[0]?.data[0]?.scalarValue)
        }
      } catch (err) {
        console.log(err)
      }
    }

    const locationPromises = locations.map(location => fetchAndStoreLocationCardStats(location?.siteId, locationCardStats))

    Promise.all(locationPromises).then(() => {
      setLocationCardStats(locationCardStats)
    });

  }, [locations]);

  const avgLat = locations.map(location => location?.latitude).reduce((a, b) => a + b, 0) / locations?.length
  const avgLong = locations.map(location => location?.longitude).reduce((a, b) => a + b, 0) / locations?.length

  return (
    <>
      <div className='flex flex-col mt-6 max-w-full'>
        <div className="text-3xl text-black font-bold">
          Site Management
        </div>
        <div className="mt-5 rounded-lg shadow-lg bg-white p-3">
          <div className="flex text-xl text-black">
            <IoMdPin fontSize={30} />
            Sites
            <div className='flex flex-1 justify-end text-sm'>
              <div className='flex items-center px-4 text-chDisp'>
                <LuPlugZap2 className='mr-2' fontSize={20} />
                <div>
                  Dispensed
                </div>
              </div>

              <div className='flex items-center px-4 text-chRem'>
                <AiOutlineCalendar className='mr-2' fontSize={20} />
                <div>
                  Drawn From Grid
                </div>
              </div>

              <div className='flex items-center px-4 mr-3 text-gray-400'>
                <AiOutlineBarChart className='mr-2' fontSize={20} />
                <div>
                  Currently Stored
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-10">
            <div className="w-2/3 m-2">
              <div className="grid grid-cols-2 gap-4">
                {
                  locations?.length > 0 ?
                    locations.map((location) => {

                      const dispensedData = (locationCardStats[location?.siteId] !== undefined) ? locationCardStats[location?.siteId]['dispensed'] : 0
                      const gridData = (locationCardStats[location?.siteId] !== undefined) ? locationCardStats[location?.siteId]['grid'] : 0
                      const storedData = (locationCardStats[location?.siteId] !== undefined) ? locationCardStats[location?.siteId]['stored'] : 0


                      return (
                        < div className="flex flex-col bg-white rounded-lg flex p-3 text-black shadow-md" >
                          {/* {JSON.stringify(location)} */}
                          < div className="flex text-lg text-center items-center mb-0.5" >
                            <div className="flex flex-col">
                              <div className="flex flex-row text-xl text-left">
                                <LuMapPin className="mt-1 mr-1 text-xl" />
                                {
                                  location?.siteName
                                }
                              </div>
                              <div className="flex flex-row text-sm text-slate-500">
                              {
                                location?.address
                              }
                              </div>
                            </div>
                            <div className="flow-root flex-1 top-0 right-0">
                              <div className="flex float-right shadow-md rounded text-base text-gray-500 px-2 text-center items-center cursor-pointer hover:bg-gray-50 hover:px-3"
                                onClick={() => navigate(`/${location?.siteId}`)}
                              >
                                Expand
                                <HiArrowsExpand className="ml-1" />
                              </div>
                            </div>
                          </div>

                          <div className="flex mt-3">
                            <div className="w-100 mr-6 aspect-square">
                              {/* Bit of hacky way to get the charger pie component to show the energy stats */}
                              <ChargerPie
                                chargerStat={
                                  {
                                    'dispensed': dispensedData,
                                    'scheduled': gridData,
                                    'possible': storedData + dispensedData + gridData,
                                  }
                                }
                                size={50}
                              />
                            </div>
                            <div className="flex-1 grid grid-cols-2 mr-3">
                              <div className="mb-5">
                                <div className="font-bold">
                                  Drawn From Grid
                                </div>
                                <div className="flex text-center items-end">
                                  <div className="text-3xl font-bold">
                                    {gridData}
                                  </div>
                                  <p className="text-gray-700 ml-1 mb-0.5">kWh</p>
                                </div>
                              </div>

                              <div className="mb-5">
                                <div className="font-bold">
                                  Dispensed to Vehicles
                                </div>
                                <div className="flex text-center items-end">
                                  <div className="text-3xl font-bold">
                                    {dispensedData}
                                  </div>
                                  <p className="text-gray-700 ml-1 mb-0.5">kWh</p>
                                </div>
                              </div>

                              <div>
                                <div className="font-bold">
                                  Currently Stored
                                </div>
                                <div className="flex text-center items-end">
                                  <div className="text-3xl font-bold">
                                    {storedData}
                                  </div>
                                  <p className="text-gray-700 ml-1 mb-0.5">kWh</p>
                                </div>
                              </div>

                            </div>
                          </div>

                          <div className="flex my-5">
                            <div className="flex-1 text-center items-center">
                              <p className="text-4xl">
                                <div className="">
                                {
                                  (locationCardStats[location?.siteId] !== undefined) ? locationCardStats[location?.siteId]['batteryCount'] : '-'
                                }
                                <PiBatteryChargingVerticalFill className='inline-block mb-1 text-batSite'fontSize={28}/>
                                </div>
                              </p>
                              <p className="text-gray-500">Batteries</p>
                            </div>
                            <div className="flex-1 text-center items-center">
                              <p className="text-4xl">
                                {
                                  (locationCardStats[location?.siteId] !== undefined) ? locationCardStats[location?.siteId]['batteryCount'] : '-'
                                }
                                <FaPlugCircleBolt className='inline-block mb-1 ml-1 text-chSite' fontSize={28} />
                              </p>
                              <p className="text-gray-500">Chargers</p>
                            </div>
                          </div>
                          <div className="font-bold ml-3 mb-3">
                            Events
                          </div>
                          <div className="h-12">
                            <ResponsiveContainer width="100%" height="100%">
                              <BarChart data={siteEventCounts[location?.siteId]}>
                                {location?.deviceTypes?.map((key, index) => (
                                  <Bar dataKey={key} stackId="a" fill={barColors[index % barColors.length]} />
                                ))}
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      )
                    })
                    :
                    <div className="text-black text-lg">0 Locations Found</div>
                }
              </div>
            </div>
            <div className="ml-3 border-2 w-1/3 m-2 rounded-md">
              {
                (locations.length > 0) &&
                <Map
                  initialViewState={{
                    // longitude: vertiportLocation?.long,
                    // latitude: vertiportLocation?.lat,
                    longitude: avgLong,
                    latitude: avgLat,
                    zoom: 2
                  }}
                  mapStyle="mapbox://styles/mapbox/light-v11"
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                >

                  {
                  locations &&
                  locations.map((location, index) => (
                    <Marker longitude={location?.longitude} latitude={location?.latitude} anchor="center" className='flex flex-row'>
                      <Pin color={'#6db5d1'} id={index + 1}/>
                    </Marker>
                  ))
                }

                </Map>
              }
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export default LocationSelection;