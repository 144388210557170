import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


const COLORS = ['#5EA15F', '#ADCFAD', '#e0e0e0'];

const ChargerPie = ({ chargerStat, size=42, pointer }) => {
  const demoUrl = 'https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o';

  const data = [
    { name: 'dispensed', value: chargerStat?.dispensed },
    { name: 'scheduled', value: chargerStat?.scheduled },
    { name: 'possible', value: Math.max(1, chargerStat?.possible - chargerStat?.scheduled - chargerStat?.dispensed) },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart cursor={pointer && "pointer"}>
        <Pie
          data={data}
          innerRadius={size * 2/3}
          outerRadius={size}
          startAngle={-270}
          // cornerRadius={40}
          cx="50%"
          cy="50%"
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ChargerPie;
