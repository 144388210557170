export const chargerSchedule = [
  [
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 0,
      'operator': 'Uber Elevate',
      'vehicleType': 'Joby Aviation eVTOL',
    },
    {
      'state': 'available',
      'duration': 144,
      'minuteOfDay': 144,
    },
    {
      'state': 'unplanned',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 288,
      'operator': 'Lilium',
      'vehicleType': 'Lilium Jet',
    },
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 432,
      'operator': 'Airbus Urban Air Mobility',
      'vehicleType': 'CityAirbus',
    },
    {
      'state': 'available',
      'duration': 144,
      'minuteOfDay': 576,
    },
    {
      'state': 'unplanned',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 720,
      'operator': 'Joby Aviation',
      'vehicleType': 'S4',
    },
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 864,
      'operator': 'Joby Aviation',
      'vehicleType': 'S4',
    },
    {
      'state': 'available',
      'duration': 144,
      'minuteOfDay': 1008,
    },
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 1152,
      'operator': 'EHang',
      'vehicleType': 'EHang 216',
    },
    {
      'state': 'available',
      'duration': 144,
      'minuteOfDay': 1296,
    },
  ],
  [
    {
      'state': 'scheduled',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 0,
      'operator': 'Uber Elevate',
      'vehicleType': 'Joby Aviation eVTOL',
    },
    {
      'state': 'available',
      'duration': 72,
      'minuteOfDay': 216,
    },
    {
      'state': 'unplanned',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 288,
      'operator': 'Lilium',
      'vehicleType': 'Lilium Jet',
    },
    {
      'state': 'scheduled',
      'duration': 72,
      'charge': '123 kWh',
      'minuteOfDay': 504,
      'operator': 'Airbus Urban Air Mobility',
      'vehicleType': 'CityAirbus',
    },
    {
      'state': 'available',
      'duration': 216,
      'minuteOfDay': 576,
    },
    {
      'state': 'unplanned',
      'duration': 72,
      'charge': '123 kWh',
      'minuteOfDay': 792,
      'operator': 'EHang',
      'vehicleType': 'EHang 216',
    },
    {
      'state': 'scheduled',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 864,
      'operator': 'Terrafugia',
      'vehicleType': 'Transition',
    },
    {
      'state': 'available',
      'duration': 72,
      'minuteOfDay': 1080,
    },
    {
      'state': 'scheduled',
      'duration': 288,
      'charge': '200 kWh',
      'minuteOfDay': 1152,
      'operator': 'Volocopter',
      'vehicleType': 'VoloCity',
    },
  ],
  [
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 0,
      'operator': 'Uber Elevate',
      'vehicleType': 'Joby Aviation eVTOL',
    },
    {
      'state': 'available',
      'duration': 144,
      'minuteOfDay': 144,
    },
    {
      'state': 'unplanned',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 288,
      'operator': 'Lilium',
      'vehicleType': 'Lilium Jet',
    },
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 432,
      'operator': 'Airbus Urban Air Mobility',
      'vehicleType': 'CityAirbus',
    },
    {
      'state': 'available',
      'duration': 144,
      'minuteOfDay': 576,
      'operator': 'EHang',
      'vehicleType': 'EHang 216',
    },
    {
      'state': 'unplanned',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 720,
      'operator': 'Terrafugia',
      'vehicleType': 'Transition',
    },
    {
      'state': 'scheduled',
      'duration': 144,
      'charge': '123 kWh',
      'minuteOfDay': 864,
      'operator': 'Volocopter',
      'vehicleType': 'VoloCity',
    },
    {
      'state': 'available',
      'duration': 432,
      'minuteOfDay': 1008,
    },
  ],
  [
    {
      'state': 'scheduled',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 0,
      'operator': 'Uber Elevate',
      'vehicleType': 'Joby Aviation eVTOL',
    },
    {
      'state': 'available',
      'duration': 72,
      'minuteOfDay': 216,
    },
    {
      'state': 'unplanned',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 288,
      'operator': 'Lilium',
      'vehicleType': 'Lilium Jet',
    },
    {
      'state': 'scheduled',
      'duration': 72,
      'charge': '123 kWh',
      'minuteOfDay': 504,
      'operator': 'Airbus Urban Air Mobility',
      'vehicleType': 'CityAirbus',
    },
    {
      'state': 'available',
      'duration': 216,
      'minuteOfDay': 576,
    },
    {
      'state': 'unplanned',
      'duration': 72,
      'charge': '123 kWh',
      'minuteOfDay': 792,
      'operator': 'EHang',
      'vehicleType': 'EHang 216',
    },
    {
      'state': 'scheduled',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 864,
      'operator': 'Terrafugia',
      'vehicleType': 'Transition',
    },
    {
      'state': 'available',
      'duration': 72,
      'minuteOfDay': 1080
    },
    {
      'state': 'scheduled',
      'duration': 216,
      'charge': '185 kWh',
      'minuteOfDay': 1152
    },
    {
      'state': 'available',
      'duration': 72,
      'minuteOfDay': 1368
    },
  ],
]