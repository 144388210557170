import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


// const COLORS = ['#5EA15F','#e0e0e0'];
const COLORS = ['#e0e0e0', '#5EA15F'];

const BatteryPie = ({ chargerStat }) => {
  const data = [
    { name: 'capacity', value: parseInt(chargerStat?.capacity) - parseInt(chargerStat?.stored) },
    { name: 'stored', value: parseInt(chargerStat?.stored) },
  ];

  return (
    <div className='h-150'>
      <ResponsiveContainer width="100%">
        <PieChart>
          <Pie
            data={data}
            innerRadius={45}
            outerRadius={60}
            startAngle={-270}
            // cornerRadius={40}
            cx="50%"
            cy="50%"
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BatteryPie;
