import { Tooltip } from 'react-tooltip';

import { CgList } from 'react-icons/cg'

import faulted_charger from 'images/faulted_charger.png'
import ready_charger from 'images/ready_charger.png'
import warning_charger from 'images/warning_charger.png'
import charging_charger from 'images/charging_charger.png'
import { chargerStats } from 'data/VertiportDashboard'

const Schedule = ({ chargerPowerStats, chargerSchedule, leftMarker, rightMarker, setSelectedCharger }) => {

  const getChargeContent = (charge) => {
    // This check is here due to some weird React issues where if I place the tooltip within the selections it doesn't show up
    if (charge?.minuteOfDay + charge?.duration < leftMarker || charge?.minuteOfDay > rightMarker) return ""
    if (charge?.state === 'available') return "Charger Available"
    return `Operator: ${charge?.operator} Vehicle: ${charge?.vehicleType}`
  }

  return (
    <div className="flex flex-1 overflow-auto flex-col bg-white rounded-xl p-3 mt-7 mb-12">
      <Tooltip id="my-tooltip" className='z-50' />

      <div className="flex items-center mb-3 text-gray-600">
        <CgList className="flex mx-2" fontSize={30} />
        <p>Schedule</p>
        <div className="flex flex-1 justify-end">

          <div className='flex items-center mr-10'>
            <div style={{ height: 20, width: 20, marginRight: 15, background: "#89a7bf" }} />
            Scheduled
          </div>

          <div className='flex items-center mr-10'>
            <div style={{ height: 20, width: 20, marginRight: 15, background: "#999999" }} />
            Unplanned
          </div>

          <div className='flex items-center mr-10'>
            <div style={{ height: 20, width: 20, marginRight: 15, background: "repeating-linear-gradient(-60deg, #89a7bf, #89a7bf 5px, #edf2f6 5px, #edf2f6  10px)" }} />
            On Demand
          </div>
        </div>
      </div>
      <div className="flex flex-1 overflow-auto">
        <div className='flex flex-col mr-6'>
          {
            chargerPowerStats.map((charger, index) => (
              <div key={index} className='cursor-pointer hover:bg-gray-100 pr-2'>
                <div className='flex items-center justify-center' style={{ height: 65 }}>
                  {
                    charger?.state == 'faulted' &&
                    <img className="w-14" src={faulted_charger} alt='aerovy logo' />
                  }
                  {
                    charger?.state == 'ready' &&
                    <img className="w-14" src={ready_charger} alt='aerovy logo' />
                  }
                  {
                    charger?.state == 'warning' &&
                    <img className="w-14" src={warning_charger} alt='aerovy logo' />
                  }
                  {
                    charger?.state == 'charging' &&
                    <img className="w-14" src={charging_charger} alt='aerovy logo' />
                  }
                  {
                    <span>{index + 1}</span>
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className='flex-1 relative'>
          <div className='absolute' style={{ height: "100%", width: "2px", background: "#000000", right: `${100 - (leftMarker / 14.4)}%` }} />
          <div className='absolute' style={{ height: "100%", width: "2px", background: "#000000", right: `${100 - (rightMarker / 14.4)}%` }} />
          {
            chargerSchedule.map((charger, index) => (
              <div key={index} className="flex" style={{ height: 60, width: "100%", marginBottom: 5 }}>
                {
                  charger.map((span, index) => (
                    <div key={index} style={{ width: `${span?.duration}%` }} data-tooltip-id="my-tooltip" data-tooltip-content={getChargeContent(span)}>
                      {
                        (span?.minuteOfDay >= leftMarker && span?.minuteOfDay + span?.duration <= rightMarker + 1) &&
                        <>
                          {
                            span?.state == "scheduled" &&
                            <div style={{ height: 60, marginRight: 4, border: "5px solid #89a7bf", background: "#89a7bf", color: "#ffffff", fontSize: 15, fontWeight: "bold" }}>
                              <div style={{ marginTop: 5 }}>
                                {span?.charge}
                              </div>
                            </div>
                          }
                          {
                            span?.state == "available" &&
                            <div style={{ height: 60, marginRight: 4, background: "#999999" }} />
                          }
                          {
                            span?.state == "unplanned" &&
                            <div style={{ height: 60, marginRight: 4, border: "5px solid #89a7bf", background: "repeating-linear-gradient(-60deg, #89a7bf, #89a7bf 3px, white 3px, white 5px)", color: "#ffffff", fontSize: 15, fontWeight: "bold" }}>
                              <div className="w-fit p-1" style={{ background: "#89a7bf", marginTop: 2 }}>
                                {span?.charge}
                              </div>
                            </div>
                          }
                        </>
                      }
                      {
                        (span?.minuteOfDay >= leftMarker && span?.minuteOfDay < rightMarker && span?.minuteOfDay + span?.duration > rightMarker + 1) &&
                        <>
                          {
                            span?.state == "scheduled" &&
                            <div style={{ height: 60, marginRight: 4, border: "5px solid #89a7bf", background: "#89a7bf", color: "#ffffff", fontSize: 15, fontWeight: "bold", width: `${(rightMarker - span?.minuteOfDay) / span?.duration * 100}%` }}>
                              <div style={{ marginTop: 5 }}>
                                {span?.charge}
                              </div>
                            </div>
                          }
                          {
                            span?.state == "available" &&
                            <div style={{ height: 60, marginRight: 4, background: "#999999", width: `${(rightMarker - span?.minuteOfDay) / span?.duration * 100}%` }} />
                          }
                          {
                            span?.state == "unplanned" &&
                            <div style={{ height: 60, marginRight: 4, border: "5px solid #89a7bf", background: "repeating-linear-gradient(-60deg, #89a7bf, #89a7bf 3px, white 3px, white 5px)", color: "#ffffff", fontSize: 15, fontWeight: "bold", width: `${(rightMarker - span?.minuteOfDay) / span?.duration * 100}%`}}>
                              <div className="w-fit p-1" style={{ background: "#89a7bf", marginTop: 2 }}>
                                {span?.charge}
                              </div>
                            </div>
                          }
                        </>
                      }
                      {
                        ( span?.minuteOfDay + span?.duration > leftMarker && span?.minuteOfDay + span?.duration < rightMarker + 1 && span?.minuteOfDay < leftMarker ) &&
                        <div>
                          {
                            span?.state == "scheduled" &&
                            <div style={{ float: 'right', height: 60, marginRight: 4, border: "5px solid #89a7bf", background: "#89a7bf", color: "#ffffff", fontSize: 15, fontWeight: "bold", width: `${(span?.minuteOfDay + span?.duration - leftMarker) / span?.duration * 100}%`  }}>
                              <div style={{ marginTop: 5 }}>
                                {span?.charge}
                              </div>
                            </div>
                          }
                          {
                            span?.state == "available" &&
                            <div style={{ float: 'right', height: 60, marginRight: 4, background: "#999999", width: `${(span?.minuteOfDay + span?.duration - leftMarker) / span?.duration * 100}%` }} />
                          }
                          {
                            span?.state == "unplanned" &&
                            <div style={{ float: 'right', height: 60, marginRight: 4, border: "5px solid #89a7bf", background: "repeating-linear-gradient(-60deg, #89a7bf, #89a7bf 3px, white 3px, white 5px)", color: "#ffffff", fontSize: 15, fontWeight: "bold", width: `${(span?.minuteOfDay + span?.duration - leftMarker) / span?.duration * 100}%` }}>
                              <div className="w-fit p-1" style={{ background: "#89a7bf", marginTop: 2 }}>
                                {span?.charge}
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            ))
          }

          <div className='flex flex-1'>
            <div className='flex-1 h-min'>00:00</div>
            <div className='flex-1 h-min'>04:00</div>
            <div className='flex-1 h-min'>08:00</div>
            <div className='flex-1 h-min'>12:00</div>
            <div className='flex-1 h-min'>16:00</div>
            <div className='flex-1 h-min'>20:00</div>
            <div className='mr-1'>24:00</div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Schedule