export const vertiport_id = 'LBG'

export const chargerStats = {
  '1': {
    'stats': {
      'Energy Discharged': {
        'value': 10,
        'units': 'kWh'
      },
      'Events': {
        'value': 5234,
        'units': ''
      }
    },
    'connectors': {
      'Connector 1': 'Clear',
      'Connector 2': 'Clear',
    }
  },
  '2': {
    'stats': {
      'Energy Discharged': {
        'value': 10,
        'units': 'kWh'
      },
      'Events': {
        'value': 5234,
        'units': ''
      }
    },
    'connectors': {
      'Connector 1': 'Clear',
      'Connector 2': 'Limited',
    }
  },
  '3': {
    'stats': {
      'Energy Discharged': {
        'value': 10,
        'units': 'kWh'
      },
      'Events': {
        'value': 5234,
        'units': ''
      }
    },
    'connectors': {
      'Connector 1': 'Clear',
      'Connector 2': 'Clear',
    }
  },
  '4': {
    'stats': {
      'Energy Discharged': {
        'value': 10,
        'units': 'kWh'
      },
      'Events': {
        'value': 5234,
        'units': ''
      }
    },
    'connectors': {
      'Connector 1': 'Clear',
    }
  },
}

export const scheduleData = [
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
  [
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Scheduled',
      'aircraft': 'Archer XC90',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'Available',
      'aircraft': '',
    },
    {
      'width': 2,
      'time': '30 min',
      'state': 'On Demand',
      'aircraft': 'Joby Alpha',
    },
  ],
]