import { FaArrowTrendUp } from 'react-icons/fa6'
import { FaArrowTrendDown } from 'react-icons/fa6'

const MetricCard = ({ metricCardStat }) => {

  return (
    <div className="h-full bg-white rounded-xl p-3">
          <div className='flex flex-col my-3 mx-5 items-center'>
            <div className='text-sm capitalize text-gray-400'>{metricCardStat.label}</div>
            <div className='text-5xl font-bold'>
              {metricCardStat?.value}
              <span className='font-thin text-base font-medium'>{metricCardStat?.units}</span>
            </div>
            <div className='flex'>
              <span className={metricCardStat?.trend > 0 ? 'text-chDisp mt-0.5' : 'text-red-400 mt-0.5'}>{metricCardStat?.trend > 0 ? <FaArrowTrendUp/> : <FaArrowTrendDown/> }</span>
              <span className='mx-1'>{Math.abs(metricCardStat?.trend)}% </span>
              <span className='text-xs text-gray-400 pt-1'>from avg</span>
            </div>
          </div>
    </div>
  )
}

export default MetricCard