import { useContext } from "react"

import { AccountContext } from 'cognito/Account'

const Settings = () => {

  const { logout } = useContext(AccountContext)

  return (
    <>
      Aerovy Settings
      <div className="bg-gray-200 w-min cursor-pointer p-2"
        onClick={() => logout()}
      >
        logout
      </div>
    </>
  )
}

export default Settings;