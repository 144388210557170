import React, { createContext, useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Pool } from '.';

const AccountContext = createContext();
const Account = (props) => {
  const [isPasswordUpdateRequired, setPasswordUpdateRequired] = useState(false);
  const getSession = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = Pool.getCurrentUser();
      if (!cognitoUser) {
        reject('Could not retrieve current user');
        return;
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject('Error retrieving user session: ', err);
          return;
        }
        if (session.isValid()) {
          resolve(session);
          localStorage.setItem("access_token", session.accessToken.jwtToken);
        }
        else {
          reject('Session is not valid');
        }
      });
    });
  };
  const authenticate = async (Username, Password) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      console.log("user: ", user);
      const authDetails = new AuthenticationDetails({ Username, Password });
      console.log("authDetails: ", authDetails);
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("Success:", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.error("Failure:", err);
          reject(err);
        },
        newPasswordRequired: () => {
          setPasswordUpdateRequired(true);
          reject('New password required');
        }
      });
    });
  };

  const updatePassword = async (Username, OldPassword, NewPassword) => {
    /*
    // It appears that the cognito user is stateful, so we can only update the password 
    // if we create a new user and call authenticate first before new password
    // I could save the cognito user as a state when authenticate is called first but I'm lazy so
    // I'm copy pasting and calling authenticate again but also passing the new password
    */
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      console.log("user: ", user);
      const authDetails = new AuthenticationDetails({ Username, OldPassword });
      console.log("authDetails: ", authDetails);
      user.authenticateUser(authDetails, {
        // onSuccess, and onFailure *should* never be called given this flow
        onSuccess: (data) => {
          console.log("Success:", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.error("Failure:", err);
          reject(err);
        },
        newPasswordRequired: () => {
          user.completeNewPasswordChallenge(NewPassword, {}, {
            onSuccess: (data) => {
              console.log("got in: ", data)
              resolve(data)
            },
            onFailure: (err) => {
              console.error("could not update password: ", err)
              reject(err)
            }
          })
        }
      });
    })
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };
  return (React.createElement(AccountContext.Provider, { value: { authenticate, getSession, updatePassword, logout, isPasswordUpdateRequired } }, props.children));
};
export { Account, AccountContext };
