import { useState } from 'react'

import { BatteryPie, ChargerPie } from 'components'

import { BiReplyAll, BiSolidBatteryCharging } from 'react-icons/bi'
import { MdPower } from 'react-icons/md'
import { LuPlugZap2 } from 'react-icons/lu'
import { AiOutlineCalendar, AiOutlineBarChart } from 'react-icons/ai'

import faulted_charger from 'images/faulted_charger.png'
import ready_charger from 'images/ready_charger.png'
import warning_charger from 'images/warning_charger.png'
import charging_charger from 'images/charging_charger.png'
import batteryImg from 'images/battery.png'

const SkyportsChargerStatus = ({ chargers, chargerStats, batteries, batteryStats, setSelectedCharger }) => {

  const [selection, setSelection] = useState('chargers')

  return (
    <div className="flex flex-col bg-white rounded-xl mr-3">
      <div className='flex border-b-2'>
        {
          selection == 'chargers' ?
            <div className='flex text-center items-center p-4 -m-px ml-3 mt-3 border-b-2 border-black cursor-pointer'
            >
              <BiReplyAll className='mr-2' />
              <div>
                Chargers
              </div>
            </div> :
            <div className='flex text-center text-gray-500 items-center p-4 ml-3 mt-3 cursor-pointer'
              onClick={() => (setSelection('chargers'))}
            >
              <BiReplyAll className='mr-2' />
              <div>
                Chargers
              </div>
            </div>
        }
        {
          selection == 'batteries' ?
            <div className='flex text-center items-center p-4 border-b-2 -m-px mr-3 mt-3 border-black cursor-pointer'>
              <BiSolidBatteryCharging className='mr-2' />
              <div>
                Batteries
              </div>
            </div>
            :
            <div className='flex text-center text-gray-500 items-center p-4 mr-3 mt-3 cursor-pointer'
              onClick={() => (setSelection('batteries'))}
            >
              <BiSolidBatteryCharging className='mr-2' />
              <div>
                Batteries
              </div>
            </div>
        }

        <div className='flex flex-1 justify-end border-l-2'>
          <div className='flex items-center p-4 mt-3 text-chDisp'>
            <LuPlugZap2 className='mr-2' fontSize={20} />
            <div>
              Dispensed
            </div>
          </div>

          <div className='flex items-center p-4 mt-3  text-chRem'>
            <AiOutlineCalendar className='mr-2' fontSize={20} />
            <div>
              Scheduled Remaining
            </div>
          </div>

          <div className='flex items-center p-4 mr-3 mt-3 text-gray-400'>
            <AiOutlineBarChart className='mr-2' fontSize={20} />
            <div>
              Possible
            </div>
          </div>
        </div>

      </div>

      <div className='grid grid-cols-4 h-370 pr-4 items-center overflow-auto'>
        {
          selection == 'chargers' ?
            chargers.length !== 0 ?
              chargers.map((stat, index) => {
                var statColor = "#999999";
                var imgSource = ready_charger
                if (stat?.state == 'faulted') {
                  statColor = "#c55b57"
                  imgSource = faulted_charger
                }
                else if (stat?.state == 'ready') {
                  statColor = "#999999"
                  imgSource = ready_charger
                }
                else if (stat?.state == 'warning') {
                  statColor = "#f2a93c"
                  imgSource = warning_charger
                }
                else if (stat?.state == 'charging') {
                  statColor = "#6e9f66"
                  imgSource = charging_charger
                }

                return (
                  <div
                    key={index}
                    className='flex flex-1 flex-col my-3 cursor-pointer hover:bg-gray-100 shadow-md rounded-lg overflow-auto ml-4 text-white h-min'
                    onClick={() => setSelectedCharger(stat)}
                  >
                    <div className='flex text-center items-center py-2' style={{ background: statColor }}>
                      <div className='ml-5 flex-1 text-start'>
                        {/* {`${stat?.version}`} */}
                      </div>
                      <MdPower fontSize={18} />
                      <div className='ml-1 font-bold mr-5'>
                        {stat?.attributes?.FriendlyName}
                      </div>
                    </div>

                    <div className='flex my-1 mr-3 my-3'>
                      <div className='w-2/3 relative'>
                        {/* <div className='relative top-1/2 left-0 text-center items-center'> */}
                        <div className='absolute flex justify-center items-center w-full h-full'>
                          {
                            <img className="w-14" src={imgSource} alt='aerovy logo' />
                          }
                        </div>
                        {/* <ChargerPie chargerStat={chargerStats[stat?.attributes?.FriendlyName]} pointer /> */}


                        {/* Manually setting charger stat key due to charger controller naming discrepancy */}
                        <ChargerPie chargerStat={chargerStats[stat?.attributes?.FriendlyName]} pointer />
                      </div>
                      <div className='flex flex-col justify-center'>
                        <div className='flex my-1 text-chDisp text-base'>
                          <LuPlugZap2 fontSize={20} />
                          {`${chargerStats[stat?.attributes?.FriendlyName]?.dispensed}`}
                          {/* <span className='text-gray-400 mr-2 ml-2'>\</span> */}
                        </div>
                        <div className='flex my-1 text-chRem text-base'>
                          <AiOutlineCalendar fontSize={20} />
                          {`${chargerStats[stat?.attributes?.FriendlyName]?.scheduled}`}
                          {/* <span className='text-gray-400 mr-2 ml-2'>\</span> */}
                        </div>
                        <div className='flex my-1 text-gray-400 text-base'>
                          <AiOutlineBarChart fontSize={20} />
                          {`${chargerStats[stat?.attributes?.FriendlyName]?.possible}`}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <div
                // key={index}
                className='flex-1 text-2xl my-3 ml-5 text-black'
              // onClick={() => setSelectedCharger(stat?.id)}
              >
                0 devices
              </div>
            :
            batteries.map((battery) => (
              <div className='flex flex-1 flex-col py-2 mt-3'>
                <div className='text-center items-center'>
                  {`${battery?.attributes?.FriendlyName}`}
                </div>

                <div className='relative'>
                  {/* <div className='relative top-1/2 left-0 text-center items-center'> */}
                  <div className='absolute flex justify-center items-center w-full h-full'>
                    {
                      <img className="w-20" src={batteryImg} alt='aerovy logo' />
                    }
                  </div>
                  <BatteryPie chargerStat={batteryStats[battery?.attributes?.FriendlyName]} />
                </div>
                <div className='place-self-center'>
                  <div className='grid grid-rows-1 grid-flow-col gap-0 w-fit'>
                    <div className='text-center text-chDisp'>
                      {`${batteryStats[battery?.attributes?.FriendlyName]?.stored}`}
                      <span className='text-gray-400 mr-2 ml-2'>\</span>
                    </div>
                    <div className='text-center text-gray-300'>
                      {`${batteryStats[battery?.attributes?.FriendlyName]?.capacity}`}
                    </div>
                    <div className='text-center text-gray-300 ml-1'>
                      {`kWh`}
                    </div>
                  </div>
                  <div className='grid grid-rows-1 grid-flow-col gap-0 w-fit'>
                    <div className='text-center text-chDisp'>
                      {`${batteryStats[battery?.attributes?.FriendlyName]?.health}`}
                      <span className='text-gray-400 mr-1 ml-1'>%</span>
                    </div>
                    <div className='text-center text-gray-300 ml-1'>
                      {`SoH`}
                    </div>
                  </div>
                </div>
              </div>
            ))
        }
      </div>
    </div>
  )
}

export default SkyportsChargerStatus