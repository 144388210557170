import { BsDot } from 'react-icons/bs'

const MiddleBar = ({ pageTree, header, subheadings, displayProps }) => {

  return (
    <div className='flex flex-col'>
      <div className="flex text-center items-center">
        <div className="flex flex-1 items-center md:text-md mt-2">
          {
            (pageTree !== undefined) &&
              pageTree?.map((data, index) => (
                <div key={index} className='flex'>
                  <p className="mx-1">{data}</p>
                  {
                    (index !== pageTree.length - 1) && <>{`>`}</>
                  }
                </div>
              ))
          }
        </div>
        {/* <div className="flex w-fit text-gray-500 md:text-sm text-xs">
          <p className="px-3">{`Day`}</p>
          <p className="px-3">{`Month`}</p>
          <p className="px-3">{`Year`}</p>
          <p className="px-3">{`YTD`}</p>
          <p className="px-3">{`All-time`}</p>
        </div> */}
      </div>
      <div className="flex text-gray-800">
        <div className="flex flex-col mr-10">
          <div className="text-5xl font-bold my-2">
            {
              (header !== undefined) ?
                header :
                ''
            }
          </div>
          <div className="flex text-2xl items-center">
            {
              subheadings?.map((data, index) => (
                <div className="flex" key={index}>
                  <div>
                    {data}
                  </div>
                  {
                    (index !== subheadings.length - 1) && <BsDot fontSize={35} />
                  }
                </div>
              ))
            }
          </div>
        </div>
        <div className="flex flex-1">
          {
            (displayProps !== undefined) &&
            Object.keys(displayProps).map((key) => (
              <div className="h-min border-2 border-white p-1 rounded-lg shadow-inner mr-3" key={key}>
                {key}
                <div className="my-1 mx-2 flex bg-white rounded-md py-1 px-2 w-fit text-black shadow-md">
                  {displayProps[key]}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default MiddleBar;