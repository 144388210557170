import { useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { AccountContext } from 'cognito/Account'
import { ToggleSwitch } from 'components'

import { MdOutlineDashboard } from 'react-icons/md'

import aerovy from 'images/aerovy_logo.png'

const commonMenuStyling = "flex rounded-t-lg border-x-2 border-t-2 items-center px-4 py-1 mr-3 "
const hoverStyling = "hover:bg-gray-100 hover:text-db "

const Topbar = ({ username, isToggled, toggle }) => {

  const { logout } = useContext(AccountContext)
  const navigate = useNavigate();
  const path = useLocation()['pathname']

  return (
    <nav className="flex bg-db text-white md:text-md text-sm">
      <img className="m-3 h-10" src={aerovy} alt='aerovy logo' />
      <div className="flex w-full justify-center mt-2">
        <Link
          to="/"
          className={commonMenuStyling + hoverStyling + (path === '/' ? "bg-gray-100 text-db" : "text-gray-300")}
        >
          <MdOutlineDashboard fontSize={30} className="mr-3" />
          <p>Dashboard</p>
        </Link>

        <div
          className={commonMenuStyling + ((path !== '/' && !path.includes('/billing')) ? "bg-gray-100 text-db" : "text-gray-300")}
        >
          <MdOutlineDashboard fontSize={30} className="mr-3" />
          <p>Live Monitor</p>
        </div>

        <Link
          to="/billing"
          className={commonMenuStyling + (path.includes('/billing') ? "bg-gray-100 text-db" : "text-gray-300")}
        >
          <MdOutlineDashboard fontSize={30} className="mr-3" />
          <p>Billing & Invoicing</p>
        </Link>
      </div>
      {/* <div className="flex py-4 pr-5 w-min">
        <ToggleSwitch label="Admin" toggled={isToggled} onClick={toggle} />
      </div> */}
      <div className="flex">
        <div className="flex flex-col p-2 w-max">
          <p className="md:text-lg">{username}</p>
          <p className="md:text-sm text-xs">Administrator</p>
        </div>
        <div className="m-2 mr-5 bg-gray-300 text-black p-2 rounded hover:bg-gray-400 items-center h-min cursor-pointer"
          onClick={() => {
            logout()
            navigate('/')
            window.location.reload(false);
          }}
        >
          <p className="md:text-sm text-xs">Logout</p>
        </div>
      </div>
    </nav>
  )
}

export default Topbar;