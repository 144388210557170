import { Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine, ComposedChart, Area, Tooltip, ReferenceDot, Text, Scatter, ScatterChart, Dot } from 'recharts';

import { BsDownload } from 'react-icons/bs'
import { BsFillLightningChargeFill } from 'react-icons/bs'
import { AiOutlineBell } from 'react-icons/ai'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p>{`Hour: ${label}`}</p>
        {payload.map((entry, index) => (
          <p key={index}>{`${entry.name}: ${entry.value} kW`}</p>
        ))}
      </div>
    );
  }

  return null;
}
const CustomActivityShape = ({ cx, cy, node, ...rest }) => {
  // return <Dot r={6} cx={cx} cy={cy} fill={node.y ? "green" : "red"} />;
  return (
    <svg width="12" height="12" fill="#f97316" class="bi bi-bell-fill" viewBox="0 0 16 16" x={cx} y={cy + 25}
      className='cursor-pointer'
      onClick={() => {
        console.log(cx, cy, node, rest)
      }}
    >
      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
    </svg>
  )
};

function createRangeArray(range) {
  const rangeArray = [];
  const diff = Math.max(1, Math.floor(range / 24))
  // Ensure a <= b for the loop to work correctly
  for (let i = 0; i <= range; i+= diff) {
    rangeArray.push(i);
  }
  return rangeArray;
}

const ChargerPowerChart = ({ usageData, generationData, upperBound }) => {

  console.log(generationData)

  return (
    <div className="flex flex-col bg-white rounded-sm shadow-lg p-3">
      <div className="flex text-2xl items-center mb-3 text-gray-600">
        <BsFillLightningChargeFill className="flex mx-2" fontSize={30} />
        <p>Energy Utilization</p>
      </div>
      <div>
        <ResponsiveContainer width="100%" aspect={3.8}>
          <ComposedChart margin={{ right: 10, bottom: 10 }} >

            {/* <Tooltip /> */}
            {/* <Tooltip content={<CustomTooltip />} />  */}

            <XAxis type="number" stroke='#b4b4b4' dataKey="x" domain={[0, upperBound]} interval={0} axisLine={false} tickLine={false} ticks={createRangeArray(upperBound)} />


            <YAxis type="number" stroke='#b4b4b4' domain={[0, 160]} />

            {/* <Line type="number" data={finalUsageData} dataKey="y" stroke="#ffffff" strokeWidth={5} dot={false} /> */}
            <Line name={"Usage"} type="number" data={usageData} dataKey="y" stroke="#f2aa3c" strokeWidth={2} dot={false} />

            {/* <Line type="number" data={finalUsageData} dataKey="y" stroke="#ffffff" strokeWidth={5} dot={false} /> */}
            <Line name={"Usage"} type="number" data={generationData} dataKey="y" stroke="#ab5fb3" strokeWidth={2} dot={false} />

            <ReferenceLine x={0} strokeWidth={2} />
            <ReferenceLine x={upperBound} strokeWidth={2} />
            <ReferenceLine y={0} />

            {/* <Scatter data={alertData} dataKey={"y"} fill="#ff0000" shape={<CustomActivityShape />} /> */}

          </ComposedChart>
        </ResponsiveContainer>

        <div className='flex m-3 p-3 text-xs'>
          <div className='flex mx-auto'>

            <div className='flex flex-col items-center mx-5'>
              <div className='bg-amber-500 rounded-md w-60 h-1.5'></div>
              <div className='font-bold mt-3'>
                {`Usage (kW)`}
              </div>
            </div>

            <div className='flex flex-col items-center mx-5'>
              <div className='bg-fuchsia-800 rounded-md w-60 h-1.5'></div>
              <div className='font-bold mt-3'>
                {`Grid Draw (kW)`}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ChargerPowerChart