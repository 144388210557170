import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { AccountContext } from 'cognito/Account'

import { FiSettings } from 'react-icons/fi'

import aerovy from 'images/aerovy_logo.png'

const Sidebar = () => {

  const { logout } = useContext(AccountContext)
  const navigate = useNavigate();

  return (
    <nav className="flex min-w-70 flex-col bg-db text-white items-center">
      <img style={{ height: 40, width: 34, marginTop: 12, marginBottom: 20 }} src={aerovy} alt='aerovy logo' className="cursor-pointer" onClick={() => navigate('/')} />
      <FiSettings fontSize={30} className="cursor-pointer" onClick={() => navigate('/settings')} />
    </nav>
  )
}

export default Sidebar;