import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AccountContext } from './Account'

import { TopbarEmpty } from 'components'


const Login = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // Change password flow
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')


  const [errMessage, setErrMessage] = useState('')

  let navigate = useNavigate();

  const { authenticate, updatePassword, isPasswordUpdateRequired } = useContext(AccountContext)

  const onSubmit = (e) => {
    e.preventDefault()
    setErrMessage('')

    if (!email || !password) {
      setErrMessage('Error: Please Fill in Username and Password Boxes')
      return
    }

    authenticate(email, password)
      .then((data) => {
        navigate('/')
        window.location.reload(false);
        console.log("Logged in: ", data)
      })
      .catch((err) => {
        console.error(err)
        if (isPasswordUpdateRequired) {
          setErrMessage('Update to new Password')
        } else {
          setErrMessage('Error: Could Not Log In')
        }
      })
  }


  const onSubmitNewPassword = (e) => {
    e.preventDefault()
    setErrMessage('')

    if (!newPassword || !confirmNewPassword) {
      setErrMessage('Error: Please Fill Password and Confirmation')
      return
    }

    if (newPassword !== confirmNewPassword) {
      setErrMessage('Error: Passwords do not match!')
      return
    }

    updatePassword(email, password, newPassword)
      .then((data) => {
        navigate('/')
        window.location.reload(false);
        console.log("Logged in: ", data)
      })
      .catch((err) => {
        console.error(err)
        setErrMessage('Error: Could not Update Password')
      })
  }

  if (isPasswordUpdateRequired) return (
    <div className="flex flex-col h-screen w-screen bg-gray-100">
      <TopbarEmpty />
      <div className='flex flex-1 bg-white border-2 items-center justify-center'>
        <form onSubmit={onSubmitNewPassword} className="flex flex-col text-xl bg-gray-50 rounded-xl border-2 p-5 w-350">
          <label htmlFor="password" >New Password</label>
          <input
            value={newPassword}
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            className="mb-5 border-2 border-gray-400 rounded p-1 w-full"
          />
          <label htmlFor="password">Confirm Password</label>
          <input
            value={confirmNewPassword}
            type="password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className="mb-5 border-2 border-gray-400 rounded p-1 w-full"
          />
          <button type='submit' className="bg-gray-200 p-2 rounded-lg hover:bg-gray-300 mb-3">Change Password</button>
          <div className="text-base">{errMessage}</div>
        </form>
      </div>
    </div>
  )


  return (
    <div className="flex flex-col h-screen w-screen bg-gray-100">
      <TopbarEmpty />
      <div className='flex flex-1 bg-white border-2 items-center justify-center'>
        <form onSubmit={onSubmit} className="flex flex-col text-xl bg-gray-50 rounded-xl border-2 p-5 w-350">
          <label htmlFor="email" >Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-5 border-2 border-gray-400 rounded p-1 w-full"
          />
          <label htmlFor="password">Password</label>
          <input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="mb-5 border-2 border-gray-400 rounded p-1 w-full"
          />
          <button type='submit' className="bg-gray-200 p-2 rounded-lg hover:bg-gray-300 mb-3">Login</button>
          <div className="text-base">{errMessage}</div>
        </form>
      </div>
    </div>
  )
}

export default Login;