export function getEventCounts(siteId) {
  const query = `SELECT bin(time, 2h), DeviceType, COUNT(*) as count FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${siteId}_Data" WHERE time > ago(168h) GROUP BY bin(time, 2h), DeviceType ORDER BY bin(time, 2h), DeviceType`
  return fetchFromTimestream(query)
}

// Should accessing other database uuids be blocked
export function getDatabases() {
  const query = "SHOW DATABASES";
  return fetchFromTimestream(query);
}

// export function getTables() {
//   const query = "SHOW TABLES FROM \"00000000-ad9d-4025-81ab-e6083532bb7b\"";
//   return fetchFromTimestream(query);
// }

export function getEventCount(startDate, endDate, deviceName) {
  const query = `SELECT COUNT(*) FROM \"00000000-ad9d-4025-81ab-e6083532bb7b\".\"35e710af-8185-4d38-bfc9-e4fe5de3d3a2_Data\" ${generateWhereClause(startDate, endDate)} ${generateTypeFilterClause(deviceName)}`;
  return fetchFromTimestream(query);
}

export function getAllEvents(startDate, endDate, deviceName) {
  const query = `SELECT * FROM \"00000000-ad9d-4025-81ab-e6083532bb7b\".\"35e710af-8185-4d38-bfc9-e4fe5de3d3a2_Data\" ${generateWhereClause(startDate, endDate)} ${generateTypeFilterClause(deviceName)}`;
  return fetchFromTimestream(query);
}

function generateWhereClause(startDate, endDate) {
    if (!startDate || !endDate) return ""
    // ORDER BY time DESC may not be needed for timestream, if included need to add clause that checks if the sql query is of the type that can be ordered
    return `WHERE time BETWEEN '${startDate}' AND '${endDate}'`;
} 

function generateTypeFilterClause(deviceName) {
  if (!deviceName) return ""
  // ORDER BY time DESC may not be needed for timestream, if included need to add clause that checks if the sql query is of the type that can be ordered
  return `AND LOWER(deviceName)='${deviceName}'`;
} 

export async function fetchFromTimestream(query) {
  const bodyData = JSON.stringify(query);

  let url = ''
  if (process.env.NODE_ENV == 'production') {
    url = 'https://data.spectra.api.aerovy.com/data/test/'
  } else {
    url = '/data/test/'
  }  

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'partner-id': '00000000-ad9d-4025-81ab-e6083532bb7b'
      },
      body: bodyData
    });

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
}
