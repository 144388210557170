export const chargerStates = [
  {
    'id': '1',
    'state': 'faulted',
    'dispensed': '100',
    'possible': '200',
    'scheduled': '24',
  },
  {
    'id': '2',
    'state': 'ready',
    'dispensed': '50',
    'possible': '200',
    'scheduled': '110',
  },
  {
    'id': '3',
    'state': 'charging',
    'dispensed': '124',
    'possible': '200',
    'scheduled': '0',
  },
  {
    'id': '4',
    'state': 'warning',
    'dispensed': '80',
    'possible': '200',
    'scheduled': '50',
  },
]

export const batteryState = [
  {
    'stored': '700',
    'capacity': '1000',
  },
]