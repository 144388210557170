import 'react-vertical-timeline-component/style.min.css';

import { MdPower } from 'react-icons/md'
import { BiSolidRightArrow } from 'react-icons/bi'

const EventTimeline = ({ chargingEventTimes, start, end }) => {
  return (
    <div className='p-5'>
      <div className="flex text-2xl items-center mb-3 text-gray-600">
        <p>Charger</p>
      </div>
      <div className='font-bold text-black pl-5 text-lg'>
        {start.toString()}
      </div>
      <div className="relative flex pl-5">
        <div className='absolute border-r-4 h-full border-gray-600' />
        <div>
          {
            chargingEventTimes?.map((event) => (
              <>
                <div className='flex items-center text-gray-600'>
                  <BiSolidRightArrow fontSize={12} />
                  <MdPower fontSize={25} className='text-black' />
                  <div className="font-bold text-black mr-3">{event?.start.slice(0, -10)}</div>
                  <div className='bg-gray-50 shadow-md p-2 my-1'>
                    <div className="font-bold text-xl">Started Charging</div>
                  </div>
                </div>
                <div className='flex items-center text-gray-600'>
                  <BiSolidRightArrow fontSize={12} />
                  <MdPower fontSize={25} className='text-black' />
                  <div className="font-bold text-black mr-3">{event?.end.slice(0, -10)}</div>
                  <div className='bg-gray-50 shadow-md p-2 my-1'>
                    <div className="font-bold text-xl">Stopped Charging</div>
                  </div>
                </div>
              </>
            ))
          }
        </div>
      </div >
      <div className='font-bold text-black pl-5 text-lg'>
        {end.toString()}
        {/* {`${end.isUTC()}`} */}
      </div>
    </div>
  )
}

export default EventTimeline;