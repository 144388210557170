import { IoMdBatteryCharging } from 'react-icons/io'
import { MdOutlinePower } from 'react-icons/md'

const StatusAlert = ({ batteryAlerts, chargerAlerts }) => {

  const batteryEventCount = batteryAlerts !== undefined ? Object.keys(batteryAlerts).map(batteryName => batteryAlerts[batteryName]?.length).reduce((a, b) => a + b, 0) : 0
  const chargerEventCount = chargerAlerts !== undefined ? Object.keys(chargerAlerts).map(chargerName => chargerAlerts[chargerName]?.length).reduce((a, b) => a + b, 0) : 0

  return (
    <div className="flex flex-col flex-1 bg-white rounded-xl my-3 mr-3 p-4">
      <div className="flex text-2xl items-center mb-2">
        <div>
          {`(${batteryEventCount + chargerEventCount}) Alerts`}
        </div>
      </div>
      <div className="flex flex-col max-h-275 overflow-auto">
        {
          (batteryEventCount + chargerEventCount === 0) ?
            <div className='flex-1 w-full h-full text-xl text-center'>
              No Events in Range
            </div> :
            <>
              {
                (batteryAlerts !== undefined) &&
                Object.keys(batteryAlerts)?.map(batteryName => (
                  batteryAlerts[batteryName]?.map(alert => {
                    var bgColor;
                    if (alert?.state == 'red') bgColor = '#c55b57'
                    if (alert?.state == 'orange') bgColor = '#f2a93c'

                    return (
                      <div className='flex text-white w-full p-1 rounded-md my-1 text-center items-center px-3' style={{ background: bgColor }}>
                        {
                          (alert?.type == 'energy') && < IoMdBatteryCharging fontSize={45} />
                        }
                        {
                          (alert?.type == 'charger') && < MdOutlinePower fontSize={45} />
                        }
                        <div className='w-fit mx-3'>
                          {alert?.message}
                        </div>
                        <div className='flex flex-1 items-end flex flex-col mr-3'>
                          <div>
                            <div className='font-light'>
                              {alert?.infoTop}
                            </div>
                            <div>
                              {alert?.infoBottom}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ))
              }
              {
                (chargerAlerts !== undefined) &&
                Object.keys(chargerAlerts)?.map(chargerName => (
                  chargerAlerts[chargerName]?.map(alert => {
                    var bgColor;
                    if (alert?.state == 'red') bgColor = '#c55b57'
                    if (alert?.state == 'orange') bgColor = '#f2a93c'

                    return (
                      <div className='flex text-white w-full p-1 rounded-md my-1 text-center items-center px-3' style={{ background: bgColor }}>
                        {
                          (alert?.type == 'energy') && < IoMdBatteryCharging fontSize={45} />
                        }
                        {
                          (alert?.type == 'charger') && < MdOutlinePower fontSize={45} />
                        }
                        <div className='w-fit mx-3'>
                          {alert?.message}
                        </div>
                        <div className='flex flex-1 items-end flex flex-col mr-3'>
                          <div>
                            <div className='font-light'>
                              {alert?.infoTop}
                            </div>
                            <div>
                              {alert?.infoBottom}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ))
              }
            </>
        }
      </div>
    </div>
  )
}

export default StatusAlert