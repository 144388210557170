import aerovy from 'images/aerovy_logo.png'

const TopbarEmpty = () => {

  return(
    <div className="flex bg-db text-white md:text-md text-sm">
      <img className="m-3 h-10" src={aerovy} alt='aerovy logo'/>
    </div>
  )
}

export default TopbarEmpty;