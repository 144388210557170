import { Line, Label, XAxis, YAxis, ResponsiveContainer, ReferenceLine, ComposedChart, Scatter } from 'recharts';
import { BsFillLightningChargeFill } from 'react-icons/bs'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);



const gradientOffset = netData => {
  if (netData === undefined) return

  const dataMax = Math.max(...netData.map((i) => i.y));
  const dataMin = Math.min(...netData.map((i) => i.y));

  if (dataMax <= 0) {
    return 0
  }
  else if (dataMin >= 0) {
    return 1
  }
  else {
    return dataMax / (dataMax - dataMin);
  }
}

function findMinMax(generationData, usageData, storageData, netData) {

  const combinedData = [...generationData, ...usageData, ...storageData, ...netData];
  const xValues = combinedData.map(item => parseFloat(item.y));

  const minY = Math.min(...xValues)
  const maxY = Math.max(...xValues)
  return { minY, maxY };
}

function createRangeArray(a, b) {
  const rangeArray = [];
  const diff = Math.max(1, Math.floor((b - a) / 24))
  // Ensure a <= b for the loop to work correctly
  for (let i = a; i <= b; i += diff) {
    rangeArray.push(i);
  }
  return rangeArray;
}

const CustomReferenceLine = props => {
  // console.log(props)
  return (
    <line x1={props.x1} y1={props.y1} x2={props.x1} y2={props.y2} stroke="#bbbbbb" strokeWidth={2}>
      <animate
        attributeName="stroke-opacity"
        values="0.2;1;1;0.2"
        dur="1.5s"
        repeatCount="indefinite"
      />
    </line>
  );
};

const getHoverText = (time, text) => {
  var hour = Math.floor(time)
  var min = Math.round((time % 1) * 60)
  if (min === 60) {
    min = 0;
    hour += 1;
  }
  return `${hour}:${min} ${text}`
}

const VertiportPowerChart = ({ generationData,
  usageData,
  storageData,
  netData,
  batteryAlerts,
  chargerAlerts,
  chargers,
  start,
  end,
  startOfDay,
  dataMarkers,
  setSelectedCharger }) => {

  /* DEFINING CUSTOM CHART FUNCTIONS */
  const CustomActivityShape = ({ cx, cy, node, ...rest }) => {
    return (
      <svg width="12" height="12" fill="#f97316" viewBox="0 0 16 16" x={cx} y={cy + 25}
        data-tooltip-id="my-tooltip" data-tooltip-content={getHoverText(rest?.payload?.x, rest?.payload?.text)}
        className={rest?.clickable ? 'cursor-pointer' : ''}
        onClick={() => {
          if (rest?.clickable) {
            const charger = chargers.find(item => item.attributes.FriendlyName === rest?.chargerId.toLowerCase());
            setSelectedCharger(charger)
          }
        }}
      >
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
      </svg>
    )
  };

  const off = gradientOffset(netData);
  /* END DEFINING CUSTOM CHART FUNCTIONS */

  /* DEFINING VARIABLES AND MAPPING ALERTS */
  const { minY, maxY } = findMinMax(generationData, usageData, storageData, netData);
  const lowerHourBound = 0;
  const upperHourBound = 24 + 24 * Math.floor(end.endOf('day').diff(start, 'hour', true) / 24);
  
  const startOfRange = startOfDay
  const endOfRange = startOfDay.add(upperHourBound, 'hours').subtract(1, 'minute')
  let batteryAlertPlot = []
  Object.keys(batteryAlerts).map(batteryName => {
    batteryAlerts[batteryName].map(alert => {
      batteryAlertPlot = batteryAlertPlot.concat({ x: alert?.x, y: minY, text: alert?.message, chargerId: alert?.infoTop, clickable: false })
    })
  })

  let chargerAlertPlot = []
  Object.keys(chargerAlerts).map(chargerName => {
    chargerAlerts[chargerName].map(alert => {
      chargerAlertPlot = chargerAlertPlot.concat({ x: alert?.x, y: minY, text: alert?.message, chargerId: alert?.infoTop, clickable: true })
    })
  })
  /* END DEFINING VARIABLES AND MAPPING ALERTS */


  return (
    <div className="flex flex-col bg-white rounded-xl p-3 ">
      <div className="flex text-2xl items-center mb-3 text-gray-600">
        <BsFillLightningChargeFill className="flex mx-2" fontSize={30} />
        <p>Energy Utilization</p>
      </div>
      <div>
        <ResponsiveContainer height={280} width={"100%"}>
          <ComposedChart margin={{ right: 10, top: 20, bottom: 10 }} >

            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4678a0" stopOpacity={1} />
                <stop offset="95%" stopColor="#4678a0" stopOpacity={0} />
              </linearGradient>
              <defs>
                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor="#6ea064" stopOpacity={1} />
                  <stop offset={off} stopColor="#c85a5a" stopOpacity={1} />
                </linearGradient>
              </defs>
            </defs>

            {/* <Tooltip /> */}
            {/* <Tooltip content={<CustomTooltip />} />  */}

            <XAxis
              type="number"
              stroke='#b4b4b4'
              dataKey="x"
              domain={[lowerHourBound, upperHourBound]}
              interval={0}
              axisLine={false}
              tickLine={false}
              ticks={createRangeArray(lowerHourBound, upperHourBound)}
            />


            {/* <YAxis type="number" stroke='#b4b4b4' domain={[-2000, 2000]} /> */}
            <YAxis type="number" stroke='#b4b4b4' domain={[Math.floor(minY), Math.floor(maxY)]} />

            {/* <Area type="number" data={storageData} dataKey="y" stroke="#ffffff" strokeWidth={5} fill="url(#colorUv)" /> */}
            <Line name={"Storage"} type="number" data={storageData} dataKey="y" stroke="#4678a0" strokeWidth={2} dot={false} />

            {/* <Line type="number" data={finalGenerationData} dataKey="y" stroke="#ffffff" strokeWidth={5} dot={false} /> */}
            <Line name={"Generation"} type="number" data={generationData} dataKey="y" stroke="#ab5fb3" fill="#ffffff" strokeWidth={2} dot={false} />

            {/* <Line type="number" data={finalUsageData} dataKey="y" stroke="#ffffff" strokeWidth={5} dot={false} /> */}
            <Line name={"Usage"} type="number" data={usageData} dataKey="y" stroke="#f2aa3c" strokeWidth={2} dot={false} />

            {/* <Line type="number" data={finalNetData} dataKey="y" stroke="#ffffff" strokeWidth={5} dot={false} /> */}
            <Line name={"Net"} type="number" data={netData} dataKey="y" stroke="url(#splitColor)" strokeWidth={2} dot={false} />

            <ReferenceLine y={0} />

            {/* <ReferenceLine x={lowerHourBound} strokeWidth={2}>
              <Label value="Tossdfsdfsdsp" offset={0} position="insideTopLeft" />
            </ReferenceLine>

            <ReferenceLine x={upperHourBound} strokeWidth={2}>
              <Label value="Tossdfsdfsdsp" offset={0} position="insideTopRight" />
            </ReferenceLine> */}

            <ReferenceLine x={lowerHourBound} strokeWidth={2}>
              <Label value={startOfRange.format('MM/DD HH:mm')} offset={2} position="insideTopLeft" />
            </ReferenceLine>

            <ReferenceLine x={upperHourBound} strokeWidth={2}>
              <Label value={endOfRange.format('MM/DD HH:mm')} offset={2} position="insideTopRight" />
            </ReferenceLine>

            {/* <ReferenceLine x={endOfDataMarker} strokeWidth={2} /> */}
            <ReferenceLine x={dataMarkers[0]} strokeWidth={2} />
            <ReferenceLine x={dataMarkers[1]} shape={CustomReferenceLine} />

            {/* <ReferenceLine x={endOfRequestMarker} shape={CustomReferenceLine} /> */}

            <Scatter
              data={batteryAlertPlot}
              dataKey={"y"}
              fill="#ff0000"
              shape={<CustomActivityShape />}
            />

            <Scatter
              data={chargerAlertPlot}
              dataKey={"y"}
              fill="#ff0000"
              shape={<CustomActivityShape />}
            />

          </ComposedChart>
        </ResponsiveContainer>

        <div className='flex m-3 p-3 text-xs'>
          <div className='flex mx-auto'>
            <div className='flex flex-col items-center mx-5'>
              <div className='bg-sky-700 rounded-md w-60 h-1.5'></div>
              <div className='font-bold mt-3'>
                {`Storage (10*kWh)`}
              </div>
            </div>

            <div className='flex flex-col items-center mx-5'>
              <div className='bg-fuchsia-800 rounded-md w-60 h-1.5'></div>
              <div className='font-bold mt-3'>
                {`Grid Draw (kW)`}
              </div>
            </div>

            <div className='flex flex-col items-center mx-5'>
              <div className='bg-amber-500 rounded-md w-60 h-1.5'></div>
              <div className='font-bold mt-3'>
                {`Usage (kW)`}
              </div>
            </div>

            <div className='flex flex-col items-center mx-5'>
              <div className='flex'>
                <div className='bg-green-700 rounded-tl rounded-bl w-30 h-1.5'></div>
                <div className='bg-red-700 rounded-tr rounded-br w-30 h-1.5'></div>
              </div>
              <div className='font-bold mt-3'>
                {`Net Energy Flow (kW)`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VertiportPowerChart