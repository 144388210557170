import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { ChargerModal } from 'pages';

import {
  StatusAlert,
  VertiportPowerChart,
  Schedule,
  EnergyCard,
  SkyportsChargerStatus,
  Pin,
} from 'components'

import { getDevices, getSites, fetchFromTimestream } from 'api'

import Map, { Marker /* GeolocateControl, FullscreenControl, NavigationControl, ScaleControl, Popup */ } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Hard coded schedule data
import { siteOwner } from 'data/skyportsDemo/SiteLabelData'
import { chargerStates } from 'data/skyportsDemo/ChargerStateData'
import { chargerSchedule } from 'data/skyportsDemo/ChargerScheduleData';

import { AiOutlineClose } from 'react-icons/ai';


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);



const VertiportDashboard = () => {

  // TODO: Hooks need to be cleaned up as well as all of data pulling
  const [selectedCharger, setSelectedCharger] = useState(null);

  const [start, setStart] = useState(dayjs().startOf('day'));
  const [end, setEnd] = useState(dayjs());
  const [useUTC, setUseUTC] = useState(false);

  const [dataMarkers, setDataMarkers] = useState([0, 0]);

  const [vertiportLocation, setVertiportLocation] = useState(undefined)
  const [batteryDevices, setBatteryDevices] = useState([]);
  const [chargerDevices, setChargerDevices] = useState([]);

  const [chargerSummaryStats, setChargerSummaryStats] = useState({})
  const [batterySummaryStats, setBatterySummaryStats] = useState({});

  const [gridDrawnSum, setGridDrawnSum] = useState(0)

  const [chargerAlerts, setChargerAlerts] = useState([])
  const [batteryAlerts, setBatteryAlerts] = useState([])

  const [finalStorageData, setFinalStorageData] = useState([])
  const [finalGenerationData, setFinalGenerationData] = useState([])
  const [finalUsageData, setFinalUsageData] = useState([])


  const { locationId } = useParams();

  const lowerMinuteBound = start.minute() + 60 * start.hour()
  const upperMinuteBound = end.minute() + 60 * end.hour()
  const startOfDay = start.startOf('day')


  const toggleTimeZone = () => {
    setUseUTC(!useUTC)
  }

  // TODO: Data pulling has no dependencies so needs to be seperated out
  useEffect(() => {
    // Puling all events from API

    getSites()
      .then(data => {
        // setLocations(data);
        const location = data.filter(location => location?.siteId === locationId)[0]
        setVertiportLocation({ long: location?.longitude, lat: location?.latitude, siteName: location?.siteName })
      })
      .catch(err =>
        console.log(err)
      );

    getDevices(locationId)
      .then(ret => {
        const batteries = ret.filter(device => device?.thingTypeName.startsWith("battery"))
        const chargers = ret.filter(device => device?.thingTypeName.startsWith("charger"))
        setBatteryDevices(batteries);
        setChargerDevices(chargers);


        fetchFromTimestream(`SELECT DATE_DIFF('minute', '${startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS')}', TimeBin) / 60.0, SUM(soc) * 200.0 as TotalSoC FROM (SELECT bin(time, 15m) as TimeBin, DeviceName, soc, ROW_NUMBER() OVER (PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC) as rn FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE DeviceType = 'battery' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}') as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`)
          .then(data => {
            const finalStorageData = data?.rows?.map(bin => { return { 'x': bin?.data[0].scalarValue, 'y': bin?.data[1].scalarValue } })
            setFinalStorageData(finalStorageData)
            if (finalStorageData?.length === 0) {
              setDataMarkers([0, 0])
            } else {
              setDataMarkers([finalStorageData[0]?.x, finalStorageData[finalStorageData?.length - 1]?.x])
            }
          })
          .catch(err => {
            console.log(err)
          })


        fetchFromTimestream(`SELECT DATE_DIFF('minute', '${startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS')}', TimeBin) / 60.0, SUM(gridDrawOff) / 1000.0 as TotalSoC FROM (SELECT bin(time, 15m) as TimeBin, DeviceName, gridDrawOff, ROW_NUMBER() OVER (PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC) as rn FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE DeviceType = 'charger' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}') as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`)
          .then(data => {
            const finalGenerationData = data?.rows?.map(bin => { return { 'x': bin?.data[0].scalarValue, 'y': bin?.data[1].scalarValue } })
            setFinalGenerationData(finalGenerationData)
          })
          .catch(err => {
            console.log(err)
          })


        fetchFromTimestream(`SELECT DATE_DIFF('minute', '${startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS')}', TimeBin) / 60.0, SUM(chargeCutoff) / 1000.0 as TotalSoC FROM (SELECT bin(time, 15m) as TimeBin, DeviceName, chargeCutoff, ROW_NUMBER() OVER (PARTITION BY DeviceName, bin(time, 15m) ORDER BY time DESC) as rn FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE DeviceType = 'charger' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}') as subquery WHERE rn = 1 GROUP BY TimeBin ORDER BY TimeBin`)
          .then(data => {
            const finalUsageData = data?.rows?.map(bin => { return { 'x': bin?.data[0].scalarValue, 'y': bin?.data[1].scalarValue } })
            setFinalUsageData(finalUsageData)
          })
          .catch(err => {
            console.log(err)
          })

        let chargerSummaryStats = {}
        let gridDrawnSum = 0;
        const fetchAndStoreChargerSummaryStats = async (chargerName, summaryDict) => {
          try {
            const summary = await fetchFromTimestream(`SELECT SUM(EnergyUsedWh) / 1000.0 AS TotalEnergyUsedkWh, SUM(GridDrawnWh) / 1000.0 AS TotalGridDrawnkWh FROM (SELECT chargeCutoff * date_diff('second', LAG(time) OVER (ORDER BY time), time) / 3600.0 AS EnergyUsedWh, gridDrawOff * date_diff('second', LAG(time) OVER (ORDER BY time), time) / 3600.0 AS GridDrawnWh FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE DeviceName = '${chargerName}' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}')`)
            summaryDict[chargerName] = {
              'dispensed': Math.floor(summary?.rows[0]?.data[0]?.scalarValue),
              'scheduled': 0,
              'possible': Math.floor(summary?.rows[0]?.data[0]?.scalarValue)
            }
            gridDrawnSum += Math.floor(summary?.rows[0]?.data[1]?.scalarValue)
          } catch (err) {
            console.log(err)
          }
        }
        const chargerSummaryPromises = chargers.map(charger => fetchAndStoreChargerSummaryStats(charger?.attributes?.FriendlyName, chargerSummaryStats))



        let batterySummaryStats = {}
        const fetchAndStoreBatterySummaryStats = async (batteryName, summaryDict) => {
          try {
            const lastSoC = await fetchFromTimestream(`SELECT soc, soh FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE DeviceName = '${batteryName}' AND time <= '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}' ORDER BY time DESC LIMIT 1`)
            summaryDict[batteryName] = {
              // 'stored': Math.floor(summary?.rows[0]?.data[0]?.scalarValue * 2000),
              'stored': Math.floor(lastSoC?.rows[0]?.data[0]?.scalarValue * 2000),
              'health': (lastSoC?.rows[0]?.data[1]?.scalarValue * 100).toFixed(2),
              // TODO: Pull from charger attributes
              'capacity': 2000
            }
          } catch (err) {
            console.log(err)
          }
        }
        const batterySummaryPromises = batteries.map(battery => fetchAndStoreBatterySummaryStats(battery?.attributes?.FriendlyName, batterySummaryStats))


        let chargerAlerts = {}
        const fetchAndStoreChargerAlerts = async (chargerName, alertsDict) => {
          try {
            const alerts = await fetchFromTimestream(`SELECT time, date_diff('minute', '${startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS')}', time) / 60.0, status FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE status != 0 AND DeviceName = '${chargerName}' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}'`)
            alertsDict[chargerName] = alerts?.rows.map(alert => {
              let state;
              let message;
              if (alert?.data[2]?.scalarValue === '1') {
                state = "orange"
                message = "Charger Faulted"
              }
              if (alert?.data[2]?.scalarValue === '2') {
                state = "red"
                message = "Charger Offline"
              }
              return (
                {
                  'type': 'charger',
                  'state': state,
                  'message': message,
                  'infoTop': chargerName,
                  'infoBottom': dayjs(alert?.data[0]?.scalarValue).format("YYYY-MM-DD HH:mm:ss"),
                  'x': alert?.data[1]?.scalarValue
                }
              )
            })
          } catch (err) {
            console.log(err)
          }
        }
        const chargerAlertsPromises = chargers.map(charger => fetchAndStoreChargerAlerts(charger?.attributes?.FriendlyName, chargerAlerts))


        let batteryAlerts = {}
        const fetchAndStoreBatteryAlerts = async (batteryName, alertsDict) => {
          try {
            const alerts = await fetchFromTimestream(`SELECT time, date_diff('minute', '${startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS')}', time) / 60.0, status FROM "00000000-ad9d-4025-81ab-e6083532bb7b"."${locationId}_Data" WHERE status != 0 AND DeviceName = '${batteryName}' AND time BETWEEN '${start.format('YYYY-MM-DD HH:mm:ss.SSS')}' AND '${end.format('YYYY-MM-DD HH:mm:ss.SSS')}'`)
            alertsDict[batteryName] = alerts?.rows.map(alert => {
              let state;
              let message;
              if (alert?.data[2]?.scalarValue === '1') {
                state = "orange"
                message = "Energy Demand Approaching Supply"
              }
              if (alert?.data[2]?.scalarValue === '2') {
                state = "red"
                message = "Energy Demand Exceeds Supply"
              }
              return (
                {
                  'type': 'energy',
                  'state': state,
                  'message': message,
                  'infoTop': batteryName,
                  'infoBottom': dayjs(alert?.data[0]?.scalarValue).format("YYYY-MM-DD HH:mm:ss"),
                  'x': alert?.data[1]?.scalarValue
                }
              )
            })
          } catch (err) {
            console.log(err)
          }
        }
        const batteryAlertsPromises = batteries.map(battery => fetchAndStoreBatteryAlerts(battery?.attributes?.FriendlyName, batteryAlerts))


        Promise.all(chargerSummaryPromises).then(() => {
          setChargerSummaryStats(chargerSummaryStats)
          setGridDrawnSum(gridDrawnSum)
        });

        Promise.all(batterySummaryPromises).then(() => {
          setBatterySummaryStats(batterySummaryStats)
        });

        Promise.all(chargerAlertsPromises).then(() => {
          setChargerAlerts(chargerAlerts)
        });

        Promise.all(batteryAlertsPromises).then(() => {
          setBatteryAlerts(batteryAlerts)
        });


      }).catch(err =>
        console.log(err)
      )

  }, [start, end, locationId, startOfDay])

  // Assuming all batteries same size of 2000kWh

  const stored = Object.keys(batterySummaryStats).map(battery => batterySummaryStats[battery]?.stored).reduce((a, b) => a + b, 0)
  const dispensedSum = Object.keys(chargerSummaryStats).map(key => chargerSummaryStats[key]?.dispensed).reduce((a, b) => a + b, 0)
  const forecasted = Math.max(0, Math.floor(dispensedSum / end.diff(start, 'hour', true) * (24 - end.diff(start, 'hour', true))))

  const energyCardStats = [
    [{
      'value': gridDrawnSum,
      'units': 'kWh',
      'label': 'Drawn from Grid',
      'trend': 0
    },
    {
      'value': dispensedSum,
      'units': 'kWh',
      'label': 'Dispensed to Vehicles',
      'trend': 0
    }],
    [{
      'value': stored,
      'units': 'kWh',
      'label': 'Currently Stored',
      'trend': 0
    },
    {
      'value': forecasted,
      'units': 'kWh',
      'label': 'Forecasted remaining Demand',
      'trend': 0
    },]
  ]


  const finalNetData = finalStorageData?.map((currentData, index, array) => {
    if (index === 0) return { x: 0, y: 0 };

    let timeDifference = currentData.x;
    let netEnergyFlow = currentData.y - array[index - 1].y;

    return { x: timeDifference, y: netEnergyFlow };
  }).slice(0, -1)

  // Can flow from prev 3 stats combined
  // const finalNetData = netData.filter(point => parseFloat(point.x) > lowerHourBound && parseFloat(point.x) < upperHourBound);


  // TODO: Aggregate the energy data to calculate the energy card stats
  // No grid energy drawn in raw battery stats, aggregate may not be pulled from events at all

  // TODO: For this demo, we can also calculate the chargerStates from aggregated energy data, dispensed coming from finalUsageData and scheduled remaining from schedule

  return (
    <>
      <div className='flex mt-6 max-w-full items-center'>
        <div className='flex-1 text-3xl'>
          {vertiportLocation?.siteName} <span className='font-light'>{`| ${siteOwner}`}</span>
        </div>
        <div className='flex'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='flex'>
              <div className='flex bg-white rounded mr-3 text-lg items-center h-[56px]'>
                <div
                  className='bg-gray-100 p-2 px-3 ml-2 my-2 cursor-pointer rounded hover:bg-gray-200'
                  onClick={toggleTimeZone}
                >
                  Switch to {useUTC ? 'Local' : 'UTC'}
                </div>
                <div
                  className='bg-gray-100 p-2 px-3 ml-2 my-2 cursor-pointer rounded hover:bg-gray-200'
                  onClick={() => {
                    setStart(dayjs().startOf('day'))
                    setEnd(dayjs().endOf('day'))
                  }}
                >
                  Today
                </div>
                <div
                  className='bg-gray-100 p-2 px-3 m-2 cursor-pointer rounded hover:bg-gray-200'
                  onClick={() => {
                    const yesterday = dayjs().subtract(1, 'day');
                    setStart(yesterday.startOf('day'))
                    setEnd(yesterday.endOf('day'))
                  }}
                >
                  Yesterday
                </div>
                <div
                  className='bg-gray-100 p-2 px-3 my-2 mr-2 cursor-pointer rounded hover:bg-gray-200'
                  onClick={() => {
                    const now = dayjs()
                    setStart(now.subtract(1, 'week'))
                    setEnd(now)
                  }}
                >
                  Week
                </div>
              </div>

              <div className='flex mr-2'>
                <DateTimePicker
                  timezone={useUTC ? "UTC" : "default"}
                  value={start}
                  onChange={setStart}
                  maxDateTime={end}
                />
              </div>
              <div className='flex mr-2'>
                <DateTimePicker
                  timezone={useUTC ? "UTC" : "default"}
                  value={end}
                  onChange={setEnd}
                  minDateTime={start}
                />
              </div>
            </div>
          </LocalizationProvider>

        </div>
      </div>
      <div className='flex mt-5 mb-5 space-x-5'>
        <div className='flex w-1/3'>
          {/* Alerts Component */}
          <StatusAlert batteryAlerts={batteryAlerts} chargerAlerts={chargerAlerts} />
        </div>
        <div className='flex'>
          {/* 3 Number Eneergy Stat Card */}
          <EnergyCard energyCardStats={energyCardStats} />
        </div>
        <div className='flex flex-1 my-3'>
          {/* Network Map */}
          <div className='flex-1 bg-white rounded-xl overflow-hidden ml-3'>
            {
              (vertiportLocation !== undefined) &&
              <Map
                initialViewState={{
                  longitude: vertiportLocation?.long,
                  latitude: vertiportLocation?.lat,
                  zoom: 12
                }}
                mapStyle="mapbox://styles/mapbox/light-v11"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              >
                {
                  chargerDevices &&
                  chargerDevices.map(charger => (
                    <Marker longitude={charger?.attributes?.long} latitude={charger?.attributes?.lat} anchor="center" className='flex flex-row'>
                      <Pin color={'#5EA15F'} id={charger?.attributes?.FriendlyName.slice(-1)} />
                    </Marker>
                  ))
                  // Marker key={index} longitude={poi.long} latitude={poi.lat} anchor="center" className='flex flex-row'>
                  //   <Pin color={'#5EA15F'} id={poi?.id} /*setSelectedCharger={setSelectedCharger}*/ />
                  // </Marker>
                }

                {
                  batteryDevices &&
                  batteryDevices.map(battery => (
                    <Marker longitude={battery?.attributes?.long} latitude={battery?.attributes?.lat} anchor="center" className='flex flex-row'>
                      <Pin color={'#6db5d1'} id={battery?.attributes?.FriendlyName.slice(-1)} />
                    </Marker>
                  ))
                }

              </Map>
            }
          </div>
        </div>
      </div>
      <div className="flex space-x-5">
        <div className="w-1/2">
          {/* Charger Status Component */}
          <SkyportsChargerStatus
            chargers={chargerDevices}
            chargerStats={chargerSummaryStats}
            batteries={batteryDevices}
            batteryStats={batterySummaryStats}
            setSelectedCharger={setSelectedCharger}
          />
        </div>

        <div className="w-1/2">
          {/* Grid Energy Draw Component */}
          <VertiportPowerChart
            generationData={finalGenerationData}
            usageData={finalUsageData}
            storageData={finalStorageData}
            netData={finalNetData}
            batteryAlerts={batteryAlerts}
            chargerAlerts={chargerAlerts}
            chargers={chargerDevices}
            start={useUTC ? start.utc() : start}
            end={useUTC ? end.utc() : end}
            startOfDay={useUTC ? startOfDay.utc() : startOfDay}
            dataMarkers={dataMarkers}
            setSelectedCharger={setSelectedCharger}
          />
        </div>
      </div>
      <div>
        {/* Schedule is hard coded for now until we get an actual VASS integration */}
        <Schedule chargerPowerStats={chargerStates} chargerSchedule={chargerSchedule} leftMarker={lowerMinuteBound} rightMarker={upperMinuteBound} setSelectedCharger={setSelectedCharger} />
      </div>

      <div
        className={`fixed top-0 right-0 h-full w-screen bg-black bg-opacity-50 z-20 transform transition-transform ${(selectedCharger !== null) ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className="h-full w-full flex justify-end items-center"
          onClick={() => setSelectedCharger(null)}
        >
          <div className="bg-white w-1/2 h-full p-4 overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <AiOutlineClose fontSize={25} onClick={() => setSelectedCharger(null)} className="cursor-pointer" />
            {
              (selectedCharger !== null) &&
              <ChargerModal
                selectedCharger={selectedCharger}
                chargerStats={chargerSummaryStats[selectedCharger?.attributes?.FriendlyName]}
                chargerAlerts={chargerAlerts[selectedCharger?.attributes?.FriendlyName]}
                start={start}
                end={end} />
            }
          </div>
        </div>
      </div>
      <div className='font-light text-lg fixed bottom-0 right-0 m-2 text-gray-400 z-30'>
        Powered By <span className='font-normal'>Aerovy</span> | Demo Use Only 2023
      </div>
    </>
  )


}

export default VertiportDashboard;