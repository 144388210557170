import MetricCard from './MetricCard'

const EnergyCard = ({ energyCardStats }) => {

  return (
    <div className="grid grid-cols-2 gap-6 mt-3 mb-3">
      {
        energyCardStats[0].map((cardItem, index) => (
          <div className='' key={index}>
            <MetricCard metricCardStat={cardItem} className=''/>
          </div>
        ))
      }
      {
        energyCardStats[1].map((cardItem, index) => (
          <div className='' key={index}>
            <MetricCard metricCardStat={cardItem} className=''/>
          </div>
        ))
      }
    </div>
  )
}

export default EnergyCard