const baseUrl = (process.env.NODE_ENV == 'production') ? 'https://ingestion.spectra.api.aerovy.com' : '/ingestion';

export async function getSites() {
  const getSitesUrl = `${baseUrl}/sites/`;
  try {
    const response = await fetch(getSitesUrl, {
      method: 'GET',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'partner-id': '00000000-ad9d-4025-81ab-e6083532bb7b'
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }

    const data = await response.json();

    return data;

  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
}

export async function getDevices(siteId) {
  const getDevicesUrl = `${baseUrl}/site/${siteId}/devices`;
  try {
    const response = await fetch(getDevicesUrl, {
      method: 'GET',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'partner-id': '00000000-ad9d-4025-81ab-e6083532bb7b'
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
}

